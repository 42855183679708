input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 0px solid rgb(255, 255, 255);
  -webkit-text-fill-color: rgb(26, 36, 54);
  -webkit-box-shadow: 0 0 0px 1000px rgb(199, 229, 249) inset;
  transition: background-color 5000s ease-in-out 0s;
  /* font-size:34px!important */
}

@-webkit-keyframes autofill {
    to {
        color: #999;
        background: transparent;
    }
}

input:-webkit-autofill {
    -webkit-animation-name: autofill;
    -webkit-animation-fill-mode: both;
}

-webkit-autofill::first-line,
-webkit-autofill,
-webkit-autofill:hover,
-webkit-autofill:focus,
-webkit-autofill:active {
  /* font-family: Times, "Times New Roman", serif !important;
  font-size:34px; */
}